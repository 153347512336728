import React from 'react';
import Image from 'next/image';
import * as styles from './index.styles';

type StepProgressBarProps = {
  steps: string[];
  currentStep: number;
};

export const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps, currentStep }) => (
  <ol css={styles.progressBar}>
    {steps.map((step, index) => (
      <Step
        key={`${step}-${index}`}
        step={step}
        current={index === currentStep}
        complete={index < currentStep}
      />
    ))}
  </ol>
);

type StepProps = {
  step: string;
  current?: boolean;
  complete?: boolean;
};

const Step: React.FC<StepProps> = ({ step, current, complete }) => {
  const className = current ? 'current' : complete ? 'complete' : '';

  return (
    <li css={styles.step} className={className}>
      <span css={styles.icon.wrapper} className={className}>
        {complete && (
          <Image
            css={styles.icon.image}
            src="/assets/icon/check--light.svg"
            width={12}
            height={12}
            alt="check"
          />
        )}
      </span>
      <span css={styles.label} className={className}>
        {step}
      </span>
    </li>
  );
};

export type { StepProgressBarProps };
