import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { IRegisterApiClient } from '~/apis/registerApiClient';
import { ConfirmEmailSection } from '~/components/domains/ConfirmEmailSection';
import { TACAndInputEmailSection } from '~/components/domains/TACAndInputEmailSection';
import { LoadingSection } from '~/components/domains/LoadingSection';
import { usePersistedBusinessType } from '~/hooks/domain/usePersistedBusinessType';
import { calcFingerprintProId } from '~/libs/calcFingerprintProId';
import { FlowType } from '~/types/flowType';
import { useGAUserPseudoId } from '~/hooks/common/useGAUserPseudoId';
import { useFlowType } from '~/hooks/common/useFlowType';

export type Props = {
  apiClient: IRegisterApiClient;
  flowType: FlowType;
  salesAgencyCode: string | null;
  salesOutsourceCode: string | null;
  getSalesforceTrackingInfo: () => {
    visitorId: number | null;
    landingUrl: string | null;
  };
};

export const StartPageV2: React.FC<Props> = (props) => {
  const [pageStatus, setPageStatus] = useState<'TAC_AND_INPUT_EMAIL' | 'SENT_TOKEN'>(
    'TAC_AND_INPUT_EMAIL'
  );
  const [email, setEmail] = useState<string | null>(null);
  const [sending, setSending] = useState(false);
  const persistedBusinessType = usePersistedBusinessType();

  const createNewTokenForSelf = useMutation(props.apiClient.createNewTokenForSelfWorkflow, {
    onSuccess: async (_data, variables) => {
      setEmail(variables.email);
      setPageStatus('SENT_TOKEN');
    },
    onSettled: () => {
      setSending(false);
    },
  });

  const { flowType } = useFlowType();
  const { loading: loadingUserPseudoId, userPseudoId } = useGAUserPseudoId();

  if (loadingUserPseudoId) {
    return <LoadingSection />;
  }

  return (
    <>
      {pageStatus === 'TAC_AND_INPUT_EMAIL' && (
        <TACAndInputEmailSection
          onClickRegister={async (formData) => {
            setSending(true);
            const salesforceTracking = props.getSalesforceTrackingInfo();
            const fingerprintProId = await calcFingerprintProId();

            createNewTokenForSelf.mutate({
              email: formData.email,
              flowType: props.flowType,
              salesforceVisitorId: salesforceTracking.visitorId,
              salesforceLandingUrl: salesforceTracking.landingUrl,
              salesAgencyCode: props.salesAgencyCode,
              fingerprintPro: fingerprintProId,
              salesOutsourceCode: props.salesOutsourceCode,
              traceId: userPseudoId,
            });

            persistedBusinessType.actions.saveBusinessType({
              selfEmployed: formData.selfEmployed,
            });
          }}
          sending={sending}
          traceId={userPseudoId ?? undefined}
          flowType={flowType}
        />
      )}
      {pageStatus === 'SENT_TOKEN' && email && (
        <ConfirmEmailSection
          email={email}
          onClickBack={() => setPageStatus('TAC_AND_INPUT_EMAIL')}
          traceId={userPseudoId ?? undefined}
          flowType={flowType}
        />
      )}
    </>
  );
};
