import React from 'react';
import Image from 'next/image';
import { applicationConfig } from '~/constants/applicationConfig';
import * as styles from './index.styles';

export type Props = React.PropsWithChildren<{}>;

export const StartPageLayout: React.FC<Props> = ({ children }) => (
  <div css={styles.outerContainer}>
    <div css={styles.container}>
      <div css={styles.logoTitle.container}>
        <div css={styles.logoTitle.logo}>
          <Image
            css={styles.logoTitle.logo}
            width={256}
            height={56}
            src={'/assets/logo/with-icon.svg'}
            alt="タイミー"
          />
        </div>
        <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
      </div>

      {children}
    </div>
  </div>
);
