import TagManager from 'react-gtm-module';
import { FlowType } from '~/types/flowType';

export const fireGAEvent = {
  initGA: () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'INIT_GA',
      },
    });
  },
  customEvents: {
    viewTACAndInputEmailSection: (
      params: {
        traceId?: string;
        flowType?: FlowType;
      } = {}
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_TAC_AND_INPUT_EMAIL_SECTION',
          ...params,
        },
      });
    },
    viewConfirmEmailSection: (
      params: {
        traceId?: string;
        flowType?: FlowType;
      } = {}
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_CONFIRM_EMAIL_SECTION',
          ...params,
        },
      });
    },
    viewInputAccountSection: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_INPUT_ACCOUNT_SECTION',
          ...params,
        },
      });
    },
    viewInputCompanyWithSearchSection: (params: {
      tokenId: number;
      token: string;
      businessForm: 'corporate' | 'selfEmployed';
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_INPUT_COMPANY_WITH_SEARCH_SECTION',
          ...params,
        },
      });
    },
    changeBusinessForm: (params: {
      tokenId: number;
      token: string;
      businessForm: 'corporate' | 'selfEmployed';
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'CHANGE_BUSINESS_FORM',
          ...params,
        },
      });
    },
    occurValidationErrorInBusinessRegistration: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'OCCUR_VALIDATION_ERROR_IN_BUSINESS_REGISTRATION',
          ...params,
        },
      });
    },
    openCorporateSearchFunction: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'OPEN_CORPORATE_SEARCH_FUNCTION',
          ...params,
        },
      });
    },
    selectManualInputOfCorporateInformation: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'SELECT_MANUAL_INPUT_OF_CORPORATE_INFORMATION',
          ...params,
        },
      });
    },
    selectCorporation: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'SELECT_CORPORATION',
          ...params,
        },
      });
    },
    clickWorkerAppLink: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: `CLICK_WORKER_APP_LINK`,
        },
      });
    },
    completeClientRegistration: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'COMPLETE_CLIENT_REGISTRATION',
        },
      });
    },
    completeCompanyAndAccountRegistration: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'COMPLETE_COMPANY_AND_ACCOUNT_REGISTRATION',
        },
      });
    },
  },
};
