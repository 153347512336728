import React from 'react';
import { Stepper } from '~/components/layouts/Stepper';
import {
  SectionFlowStepper,
  Props as SectionFlowStepperProps,
} from '~/components/domains/SectionBase/components/SectionFlowStepper';
import * as styles from './index.styles';
import Image from 'next/image';

export type SectionProps =
  | {
      isStep: true;
      stepProps: SectionFlowStepperProps;
    }
  | {
      isStep?: false;
      title?: string;
      subTitle?: React.ReactNode;
      besideTitle?: React.ReactNode;
      node: React.ReactNode;
    };

export type Props = {
  currentStep: number;
  steps: string[];
  title?: string;
  subTitle?: string;
  header?: {
    node: React.ReactNode;
    align?: 'left' | 'right';
  };
  centeringTitle?: boolean;
  descriptionIcon?: string;
  descriptionIconAlt?: string;
  sections: SectionProps[];
};

export const SectionBase: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div css={styles.stepperContainer}>
        <Stepper currentStep={props.currentStep} steps={props.steps} />
      </div>

      <section css={styles.outerContainer}>
        {props.header?.node && (
          <div css={styles.header} style={{ textAlign: props.header.align }}>
            {props.header?.node}
          </div>
        )}
        <div css={styles.container}>
          {props.title && (
            <div css={[styles.title.base, props.centeringTitle && styles.title.centering]}>
              {props.title}
            </div>
          )}

          {props.subTitle && <div css={styles.subTitle}>{props.subTitle}</div>}

          {props.descriptionIcon && (
            <Image
              src={props.descriptionIcon}
              css={styles.icon}
              width={96}
              height={96}
              alt={props.descriptionIconAlt || ''}
            />
          )}

          {props.sections.map((section, idx) => (
            <div key={idx}>
              {section.isStep ? (
                <SectionFlowStepper {...section.stepProps} />
              ) : (
                <>
                  {section.title && (
                    <div css={styles.section.title}>
                      {section.title}
                      {section.besideTitle}
                    </div>
                  )}
                  {section.subTitle && <div css={styles.section.subTitle}>{section.subTitle}</div>}
                  <div css={styles.section.content}>{section.node}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
