import { mediaQueries } from '~/styles/mediaQueries';
import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textCss } from '~/styles/textCss';

// SP
export const spContainer = css`
  ${mediaQueries.desktop} {
    display: none;
  }
`;

// PC
export const pcContainer = css`
  display: flex;
  width: 100%;
  overflow: hidden;
  gap: 4px;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

export const stepContainer = {
  base: css`
    ${textCss({ color: colors.gray3, size: 's' })}

    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    line-height: 32px;
  `,
  active: css`
    color: ${colors.accent};
    font-weight: bold;
    min-width: auto;
  `,
  completed: css`
    color: ${colors.accent};
  `,
};

export const stepTextContainer = css`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
`;

export const stepText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const stepBar = {
  base: css`
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: ${colors.gray5};
    font-weight: 200; /* NOTE: acitveを目立たせるため */
  `,
  active: css`
    background-color: ${colors.accent};
  `,
};
