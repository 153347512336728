import { css } from '@emotion/react';
import { colors } from '~/styles/colors';

export const progressBar = css`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding-bottom: 26px;
  list-style: none;
  position: relative;
`;

export const step = css`
  text-align: center;
  position: relative;
  flex: 1;

  &::before,
  &::after {
    content: '';
    height: 6px;
    background-color: ${colors.gray5};
    position: absolute;
    z-index: 1;
    width: 100%;
    left: -50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    background-color: ${colors.progressBar};
    width: 0%;
  }

  &:first-of-type::before,
  &:first-of-type::after {
    display: none;
  }

  &.complete + &.current::after,
  &.complete + &.complete::after {
    width: 100%;
  }
`;

export const icon = {
  wrapper: css`
    width: 16px;
    height: 16px;
    background-color: ${colors.gray5};
    fill: ${colors.gray5};
    border-radius: 50%;
    padding: 2px;
    max-width: 100%;
    z-index: 10;
    position: relative;
    display: inline-block;

    &.current,
    &.complete {
      background-color: ${colors.progressBar};
      filter: drop-shadow(0 4px 4px rgb(153 153 153 / 25%));
    }
  `,
  image: css`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
};

export const label = css`
  display: block;
  position: absolute;
  padding-top: 12px;
  width: 100%;
  font-size: 12px;
  color: ${colors.gray4};

  &.current {
    color: ${colors.black};
  }
`;
