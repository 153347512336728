import React from 'react';
import * as styles from './index.styles';
import Image from 'next/image';
import { StepProgressBar } from './components/StepProgressBar';

export type Props = {
  steps: string[];
  currentStep: number;
};

export const Stepper: React.FC<Props> = (props: Props) => {
  const checkIcon = <Image src={'/assets/icon/check--accent.svg'} width={24} height={24} alt="" />;

  const stepStatus = (idx: number): 'COMPLETED' | 'ACTIVE' | 'NOT_YET' =>
    idx < props.currentStep ? 'COMPLETED' : idx === props.currentStep ? 'ACTIVE' : 'NOT_YET';

  return (
    <>
      <div css={styles.spContainer}>
        <StepProgressBar steps={props.steps} currentStep={props.currentStep} />
      </div>

      <ol css={styles.pcContainer}>
        {props.steps.map((stepName, idx) => (
          <li
            css={[
              styles.stepContainer.base,
              stepStatus(idx) === 'COMPLETED'
                ? styles.stepContainer.completed
                : stepStatus(idx) === 'ACTIVE'
                  ? styles.stepContainer.active
                  : null,
            ]}
            key={idx}
          >
            <div css={styles.stepTextContainer}>
              {stepStatus(idx) === 'COMPLETED' ? checkIcon : <div>{idx + 1}.</div>}
              <div css={styles.stepText}>{stepName}</div>
            </div>
            <div
              css={[
                styles.stepBar.base,
                (stepStatus(idx) === 'COMPLETED' || stepStatus(idx) === 'ACTIVE') &&
                  styles.stepBar.active,
              ]}
            />
          </li>
        ))}
      </ol>
    </>
  );
};
