import React, { useEffect } from 'react';
import Image from 'next/image';
import { linkStyle } from '~/styles/linkStyle';
import { fireGAEvent } from '~/libs/fireGAEvent';
import { applicationConfig } from '~/constants/applicationConfig';
import * as styles from './index.styles';
import { FlowType } from '~/types/flowType';

export type Props = {
  email: string;
  onClickBack: () => void;
  abTestName?: string;
  abTestPattern?: string;
  traceId?: string;
  flowType?: FlowType;
};

export const ConfirmEmailSection: React.FC<Props> = ({ email, onClickBack, ...gaParams }) => {
  useEffect(() => {
    fireGAEvent.customEvents.viewConfirmEmailSection(gaParams);
  }, []);

  return (
    <div css={styles.container}>
      <h2 css={styles.heading}>送信されたメールをご確認ください</h2>
      <p>
        <Image src="/assets/icon/send-email.svg" alt="" width="96" height="96" />
      </p>
      <div css={styles.note}>
        <p>
          <span css={styles.email.src}>{applicationConfig.supportEmail}</span> から{' '}
          <span css={styles.email.dest}>{email}</span> に認証用URLを送信しました。
        </p>
        <p>
          メールが届かない場合は入力されたメールアドレスが間違っている可能性があります。
          <span css={linkStyle()} onClick={onClickBack}>
            こちら
          </span>
          から再度メールアドレスをご登録ください。
        </p>
      </div>
    </div>
  );
};
